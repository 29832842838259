<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white">
        <div class="mb-2">
          <TabCompo @setNewTabActive="tabsSet" :tabsList="tabs" />
        </div>
      </div>
      <div class="rounded bg-white grid grid-cols-12 " :style="`height:` +  mainHeight + `px; !important`">
        <div class=" mx-2 shadow-md relative" :class="isFilterOpen ? 'box col-span-9' : 'box col-span-12'">
          <div class="bg-white cust_card p-2 heading-3 text-text1 justify-between flex">
            <div class="flex items-center">
              <span>{{activeIndex === 0 ? 'ExtremeDogFence.com' : 'ExtremeDogFence.com - Reconciliation'}}</span>
              <div v-if="fromApiStartDate !== '' && fromApiEndDate !== '' && firstLoading && !isSelectCustom && !isFilterOpen" class="flex items-center">
                <p class="text-text2 heading-5 pl-2 pt-0.5 font-semibold">
                  <span class="text-text2 heading-5 font-semibold">{{fromApiStartDate | DMMYYYYddd}}</span> 
                  To
                  <span class="text-text2 heading-5 font-semibold">{{fromApiEndDate | DMMYYYYddd}}</span>
                </p>
                <div style="letter-spacing: 1px;" class="px-2 py-0.5 ml-2  heading-7 rounded flex items-center border border-primary mt-0.5">
                  {{this.selectedValueinText}}
                </div>
              </div>
              <div v-if="dateDiffCount !== '' && firstLoading && isSelectCustom && !isFilterOpen" class="flex items-center">
                <p class="text-text2 heading-5 pl-2 pt-0.5 font-semibold">
                  <span class="text-text2 heading-5 font-semibold">{{fromApiStartDate | DMMYYYYddd}}</span> 
                  To
                  <span class="text-text2 heading-5 font-semibold">{{fromApiEndDate | DMMYYYYddd}}</span>
                </p>
                <div style="letter-spacing: 1px;" class="px-2 py-0.5 ml-2  heading-7 rounded flex items-center border border-primary mt-0.5">
                  {{this.dateDiffCount}} Days
                </div>
              </div>
            </div>
            <div class="flex">
              <button @click="exportReport()" 
              :class="`
                ${firstLoading && (ExtFListingObj.length > 0 || ExtFListingObjRec !== null) ? 'text-white bg-primary2 cursor-pointer' : 'cursor-not-allowed bg-gray-300 tet-text2 filter opacity-60'}
                ${isFilterLock ? 'mr-0' : 'mr-10'}
                `"
                style="letter-spacing: 1px;" class="px-2 cust_card heading-6 rounded flex items-center">
                <!-- <i class="fas fa-file-export h-5 w-5 text-white"></i> -->
                Export to Excel
              </button>
            </div>
            <div class="top-0 absolute" :class="isFilterOpen ? '-right-4' : 'transition-all delay-75 -right-2'" v-if="!isFilterLock">
              <button id="toggle" class="bg-gray3 hover:bg-gray4 transition delay-75 w-11 h-11 rounded-l-full " @click="openCloseFilter()">
                <span v-if="isFilterOpen"><i class="fas fa-caret-right h-6 w-6 text-white mt-1"></i></span>
                <p v-if="!isFilterOpen"><i class="fas fa-caret-left h-6 w-6 text-white mt-1"></i></p>
              </button>
            </div>
          </div>
          <div v-show="activeIndex === 0">
            <listing :firstLoading="firstLoading" :ExtFListingObj="ExtFListingObj" :startDate="filterObject.startDate" :endDate="filterObject.endDate"></listing>
          </div>
          <div v-show="activeIndex === 1">
            <reconciliation :firstLoading="firstLoading" :ExtFListingObj="ExtFListingObjRec" :startDate="filterObject.startDate" :endDate="filterObject.endDate"></reconciliation>
          </div>
        </div>
        <div class="box col-span-3 overflow-y-auto mx-2 shadow-md transition-transform delay-300 ease-in-out transition-2" id="mainDiv" v-if="isFilterOpen">
          <div class="bg-white">
            <div class=" bg-gray3 p-2 items-center justify-between flex h-11 sticky top-0" :class="isFilterLock ? 'rounded-md' : ''" >
              <div class="flex items-center">
                <div class="pr-3 heading-5 text-white cursor-pointer" v-if="!isFilterLock" @click="lockBtn()"><i class="fa-solid fa-unlock"></i></div>
                <span class="pr-3 heading-5 text-white cursor-pointer" v-if="isFilterLock" @click="lockBtn()"><i class="fa-solid fa-lock"></i></span>
                <div class="heading-4 text-white font-bold">Filter</div>
              </div>
              <span class="centerLine">
                <!-- <div class=" text-white cursor-pointer hover:underline mr-2" @click="resetAll()">
                  <button style="letter-spacing: 1px;" class="border border-gray1 px-3 py-1 cust_card heading-7">Reset</button>
                </div> -->
                <div class=" text-white cursor-pointer hover:underline" @click="applyFilter()">
                  <button style="letter-spacing: 1px;" class="border border-primary bg-primary px-3 py-1 cust_card heading-7">Apply</button>
                </div>
              </span>
            </div> 
            <div class="pt-1">
              <div v-if="filterObject.startDate !== '' || filterObject.endDate !== ''">
                <p class="text-text1 heading-6 font-bold pl-2">
                  <span class="text-text2 heading-6 font-bold">{{filterObject.startDate | DMMYYYYddd}}</span> 
                  To
                  <span class="text-text2 heading-6 font-bold">{{filterObject.endDate | DMMYYYYddd}}</span>
                </p>
              </div>
              <div class="pt-1 pl-1">
                <button style="letter-spacing: 1px"
                v-for="(data, index) in buttonFiltersArray" :key="index"
                  @click="getDateTimeForFilterHandler(index, true)"
                  :class="data.selected ? 'text-white bg-primary2' : 'text-primary2 bg-white hover:bg-blue-50'"
                  class="m-1 px-1 py-0.5 heading-7 rounded-md border border-primary2"
                  >{{data.title}}</button
                >
              </div>
            </div>
            <div class="divider m-1"></div>
            <div class="pr-2 pt-1 pb-1  flex justify-end text-right">
              <button  @click="shoeExtFilter()" style="letter-spacing: 0.5px;" class="bg-primary text-white  rounded-md px-2 heading-7 py-1">{{isShowAllFilter ? 'Hide Additional Filters' : 'Show Additional Filters'}}</button>
            </div>
            <div v-if="isShowAllFilter">
              <div class="p-2 pt-0 my-1 mt-0 cust_card" >
                <div class="centerLine justify-between ">
                  <div class="centerLine">
                    <span class=" text-text2 heading-6 font-bold">City</span>
                    <div class="loader2 ease-linear ml-2" v-if="isCityLoading"></div>
                  </div>
                  <div class="centerLine pt-2 pb-1 ">
                    <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="tempCityList.length > 0">({{tempCityList.length}} Selected)</div>
                    <!-- <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="tempCityList.length === 0 && citySelAll">({{parentCityList.length}} Selected)</div>
                    <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="tempCityList.length === 0 && !citySelAll">(Total {{parentCityList.length}} Fulfillment Status)</div> -->
                    <div class="pr-2 cursor-pointer" @click="showSearch = !showSearch">
                      <span v-if="!showSearch"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                      <p v-if="showSearch"><i class="fas fa-times h-4 w-4 text-gray4"></i></p>
                    </div>
                    <div class="pr-2 ">
                      <button @click="resetCity()" style="letter-spacing: 0.5px;" class="border border-gray-400 rounded-md px-2 heading-7">Clear</button>
                      <!-- <p class="cursor-pointer" @click="resetCity()" v-if="filterObject.city.length !== 0 || citySearch !== ''"><i class="fas fa-solid fa-filter-circle-xmark h-4 w-4 text-gray4"></i></p> -->
                    </div>
                    <span @click="selectedAll('city')" class="centerLine" v-if="parentCityList.length > 0">
                      <input type="checkbox" class="w-4 h-4 cursor-pointer" v-model="citySelAll">
                        <div class="text-text1 pl-1 heading-6 cursor-pointer">
                          {{!citySelAll ? 'Select All' : 'Deselect All'}}
                        </div>
                    </span>
                  </div>
                </div>
                <div style="display:flex" class=" text-text2 bg-white border border-gray-500 rounded-md px-2 py-1 items-center h-7" v-if="showSearch">
                  <span class="mr-2 cursor-pointer"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                  <input class="focus:outline-none w-96 text-text2" autocomplete="off" type="text" name="" placeholder="City Search" id="custSearchInput1"
                    v-model="citySearch"
                  >
                  <span v-if="citySearch.length > 0" class="cursor-pointer"  @click="clearCitySearch()">
                    <i class="fas fa-times h-4 w-4 text-gray4"></i>
                  </span>
                </div>
                <div class="overflow-auto" style="height: 150px !important;" v-if="parentCityList.length > 0">
                  <div v-for="(data, index) in parentCityList" :key="index">
                    <div class="centerLine" @click="selectData(data, index, 'city')">
                      <input type="checkbox" class="h-4 w-4 cursor-pointer" v-model="data.isSelected" >
                      <p class=" text-text1 pl-1 heading-6 cursor-pointer">{{data.displayName}}</p>
                    </div>
                  </div>
                </div>
                <div class="layout" v-if="parentCityList.length === 0 && isRefresh">
                  <div class="flex p-1 text-sm text-text1 bg-gray2 rounded items-center" role="alert">
                    <div>
                      <p class=" font-medium heading-5">No record found.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-2 my-1 cust_card group" >
                <div class="centerLine justify-between ">
                  <div class="centerLine">
                    <span class=" text-text2 heading-6 font-bold">State</span>
                    <div class="loader2 ease-linear ml-2" v-if="isStateLoading"></div>
                  </div>
                  <div class="centerLine  pt-2 pb-1">
                    <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="tempStateList.length > 0">({{tempStateList.length}} Selected)</div>
                    <div class="pr-2 cursor-pointer" @click="showSearchState = !showSearchState">
                      <span v-if="!showSearchState"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                      <p v-if="showSearchState"><i class="fas fa-times h-4 w-4 text-gray4"></i></p>
                    </div>
                    <div class="pr-2 ">
                      <button  @click="resetState()" style="letter-spacing: 0.5px;" class="border border-gray-400 rounded-md px-2 heading-7 cursor-pointer">Clear</button>
                    </div>
                    <span @click="selectedAll('state')" class="centerLine cursor-pointer" v-if="parentStateList.length > 0">
                      <input type="checkbox" class="w-4 h-4 cursor-pointer" v-model="stateSelAll" >
                      <div class="text-text1 pl-1 heading-6 cursor-pointer">
                        {{!stateSelAll ? 'Select All' : 'Deselect All'}}
                      </div>
                    </span>
                  </div>
                </div>
                <div style="display:flex" class=" text-text2 bg-white border border-gray-500 rounded-md px-2 py-1 items-center h-7" v-if="showSearchState">
                  <span class="mr-2 cursor-pointer"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                  <input class="focus:outline-none w-96 text-text2" autocomplete="off" type="text" name="" placeholder="State Search" id="custSearchInput2"
                    v-model="stateSearch"
                  >
                  <span v-if="stateSearch.length > 0" class="cursor-pointer"  @click="clearStateSearch()">
                    <i class="fas fa-times h-4 w-4 text-gray4"></i>
                  </span>
                </div>
                <div class="overflow-auto" style="height: 150px !important;" v-if="parentStateList.length > 0">
                  <div v-for="(data, index) in parentStateList" :key="index">
                    <div class="centerLine" @click="selectData(data, index, 'state')">
                      <input type="checkbox" class="h-4 w-4 cursor-pointer" v-model="data.isSelected" >
                      <p class=" text-text1 pl-1 heading-6 cursor-pointer">{{data.displayName}}</p>
                    </div>
                  </div>
                </div>
                <div class="layout" v-if="parentStateList.length === 0 && isRefresh">
                  <div class="flex p-1 text-sm text-text1 bg-gray2 rounded items-center" role="alert">
                    <div>
                      <p class=" font-medium heading-5">No record found.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-2 my-1 cust_card group">
                <div class="centerLine justify-between ">
                  <div class="centerLine">
                    <span class=" text-text2 heading-6 font-bold">Zip</span>
                    <div class="loader2 ease-linear ml-2" v-if="isZipLoading"></div>
                  </div>
                  <div class="centerLine pt-2 pb-1">
                    <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="tempPinList.length > 0">({{tempPinList.length}} Selected)</div>
                    <div class="pr-2 cursor-pointer" @click="showSearchZip = !showSearchZip">
                      <span v-if="!showSearchZip"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                      <p v-if="showSearchZip"><i class="fas fa-times h-4 w-4 text-gray4"></i></p>
                    </div>
                    <div class="pr-2 ">
                      <button  @click="resetZip()" style="letter-spacing: 0.5px;" class="border cursor-pointer  border-gray-400 rounded-md px-2 heading-7">Clear</button>
                    </div>
                    <span @click="selectedAll('zip')" class="centerLine cursor-pointer " v-if="parentPinList.length > 0">
                      <input type="checkbox" class="w-4 h-4 cursor-pointer" v-model="zipSelAll" >
                        <div class="text-text1 pl-1 heading-6 cursor-pointer">
                          {{!zipSelAll ? 'Select All' : 'Deselect All'}}
                        </div>
                    </span>
                  </div>
                </div>
                <div style="display:flex" class=" text-text2 bg-white border border-gray-500 rounded-md px-2 py-1 items-center h-7" v-if="showSearchZip">
                  <span class="mr-2 cursor-pointer"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                  <input class="focus:outline-none w-96 text-text2" autocomplete="off" type="text" name="" placeholder="Zip Search" id="custSearchInput3"
                    v-model="pinSearch"
                  >
                  <span v-if="pinSearch.length > 0" class="cursor-pointer"  @click="clearPinSearch()">
                    <i class="fas fa-times h-4 w-4 text-gray4"></i>
                  </span>
                </div>
                <div class="overflow-auto" style="height: 150px !important;" v-if="parentPinList.length > 0">
                  <div v-for="(data, index) in parentPinList" :key="index">
                    <div class="centerLine" @click="selectData(data, index, 'zip')">
                      <input type="checkbox" class="h-4 w-4 cursor-pointer" v-model="data.isSelected">
                      <p class=" text-text1 pl-1 heading-6 cursor-pointer">{{data.displayName}}</p>
                    </div>
                  </div>
                </div>
                <div class="layout" v-if="parentPinList.length === 0 && isRefresh">
                  <div class="flex p-1 text-sm text-text1 bg-gray2 rounded items-center" role="alert">
                    <div>
                      <p class=" font-medium heading-5">No record found.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-2 my-1 cust_card group">
                <div class="centerLine justify-between ">
                  <div class="centerLine">
                    <span class=" text-text2 heading-6 font-bold">Payment Method</span>
                    <div class="loader2 ease-linear ml-2" v-if="isPaymentLoading"></div>
                  </div>
                  <div class="centerLine  pt-2 pb-1">
                    <div style="letter-spacing: 0.5px;" class="px-2 py-0.5 heading-7" v-if="tempPaymentList.length > 0">({{tempPaymentList.length}} Selected)</div>
                    <div class="pr-2 cursor-pointer" @click="showSearchPayment = !showSearchPayment">
                      <span v-if="!showSearchPayment"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                      <p v-if="showSearchPayment"><i class="fas fa-times h-4 w-4 text-gray4"></i></p>
                    </div>
                    <div class="pr-2">
                      <button  @click="resetpayment()" style="letter-spacing: 0.5px;" class="border border-gray-400 rounded-md px-2 heading-7 cursor-pointer">Clear</button>
                    </div>
                    <span @click="selectedAll('payment')" class="centerLine cursor-pointer" v-if="parentPaymentList.length > 0">
                      <input type="checkbox" class="w-4 h-4 cursor-pointer" v-model="paymentSelAll" >
                      <div class="text-text1 pl-1 heading-6 cursor-pointer">
                        {{!paymentSelAll ? 'Select All' : 'Deselect All'}}
                      </div>
                    </span>
                  </div>
                </div>
                <div style="display:flex" class=" text-text2 bg-white border border-gray-500 rounded-md px-2 py-1 items-center h-7" v-if="showSearchPayment">
                  <span class="mr-2 cursor-pointer"><i class="fas fa-magnifying-glass h-4 w-4 text-gray4"></i></span>
                  <input class="focus:outline-none w-96 text-text2" autocomplete="off" type="text" name="" placeholder="Payment Search" id="custSearchInput4"
                    v-model="paymentSearch"
                  >
                  <span v-if="paymentSearch.length > 0" class="cursor-pointer"  @click="clearPaymentSearch()">
                    <i class="fas fa-times h-4 w-4 text-gray4"></i>
                  </span>
                </div>
                <div class="overflow-auto" style="height: 100px !important;" v-if="parentPaymentList.length > 0">
                  <div v-for="(data, index) in parentPaymentList" :key="index">
                    <div class="centerLine" @click="selectData(data, index, 'payment')">
                      <input type="checkbox" class="h-4 w-4 cursor-pointer" v-model="data.isSelected">
                      <p class=" text-text1 pl-1 heading-6 cursor-pointer">{{data.displayName}}</p>
                    </div>
                  </div>
                </div>
                <div class="layout" v-if="parentPaymentList.length === 0 && isRefresh">
                  <div class="flex p-1 text-sm text-text1 bg-gray2 rounded items-center" role="alert">
                    <div>
                      <p class=" font-medium heading-5">No record found.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white container" v-if="showCustomDatepicker" >
      <div class="popup_overlay">
        <CustomDateSelector :appliedFilters="filterObject" @closeSelectedDialog="customDateListener" v-if="showCustomDatepicker"/>
      </div>
    </div>
</div>
</template>
<script>
import TabCompo from '@/View/components/tabs.vue'
import listing from './component/listing.vue';
import reconciliation from './component/reconciliation.vue';
import axios from 'axios'
import deboucneMixin from '@/mixins/debounce.js'
import {downloadFile} from '@/utils/common.js'
import moment from 'moment'
import API from '@/api/App.js'
import CustomDateSelector from '@/View/components/customDateSelection'
import {getDayAccordingToLimits} from '@/utils/convertDateAndTime.js'
// import Pagination from '@/View/components/pagination.vue'
export default {
  components: {
    TabCompo,
    reconciliation,
    listing,
    CustomDateSelector,
  },
  mixins: [deboucneMixin],
  data () {
    return {
      isFilterLock: false,
      activeIndex: 0,
      tabs: [
        {name: 'Report', active: true},
        {name: 'Reconciliation', active: false},
      ],
      isShowAllFilter: false,
      fromApiStartDate: '',
      fromApiEndDate: '',
      dateDiffCount: '',
      isSelectCustom: false,
      showSearch: false,
      showSearchState: false,
      showSearchZip: false,
      showSearchPayment: false,
      tempCityList: [],
      tempStateList: [],
      tempPinList: [],
      tempPaymentList: [],
      citySelAll: true,
      stateSelAll: true,
      zipSelAll: true,
      paymentSelAll: true,
      totalCount: 0,
      isFilterOpen: true,
      isRefresh: true,
      searchVal: '',
      citySearch: '',
      stateSearch: '',
      pinSearch: '',
      paymentSearch: '',
      searchLoader: false,
      ExtFListingObj: [],
      ExtFListingObjRec: null,
      selectAllCheck: false,
      parentCityList: [],
      parentStateList: [],
      parentPinList: [],
      parentPaymentList: [],
      parentListDisplay: [],
      requiredDataListing: [],
      paymentDebounce: () => {},
      zipDebounce: () => {},
      stateDebounce: () => {},
      cityDebounce: () => {},
      isCityLoading: false,
      isStateLoading: false,
      isZipLoading: false,
      isPaymentLoading: false,
      pagination: {
        sortBy: 'amazonSKU',
        descending: true
      },
      buttonFiltersArray: [
        // {
        //   title: 'Today',
        //   selected: false,
        //   value: 'today'
        // },
        {
          title: 'Yesterday',
          selected: false,
          value: 'yesterday'
        },
        // {
        //   title: 'Last Week',
        //   selected: false,
        //   value: 'lastWeek'
        // },
        // {
        //   title: 'Last 7 Days',
        //   selected: false,
        //   value: 'thisWeek'
        // },
        {
          title: 'This Month',
          selected: false,
          value: 'thisMonth'
        },
        {
          title: 'Last Month',
          selected: false,
          value: 'lastMonth'
        },
        {
          title: 'Last 30 Days',
          selected: true,
          value: 'last30Days'
        },
        {
          title: 'Last 60 Days',
          selected: false,
          value: 'last60Days'
        },
        {
          title: 'Last 90 Days',
          selected: false,
          value: 'last90Days'
        },
        {
          title: 'Last 120 Days',
          selected: false,
          value: 'last120Days'
        },
        {
          title: 'Last 180 Days',
          selected: false,
          value: 'last180Days'
        },
        {
          title: 'Last 365 Days',
          selected: false,
          value: 'lastYear'
        },
        {
          title: 'Custom',
          selected: false,
          value: 'custom'
        }
      ],
      selectedValue: 'last30Days',
      selectedValueinText: 'Last 30 Days',
      filterObject: {
        city: [],
        state: [],
        pinCode: [],
        payment: [],
        startDate: '',
        endDate: '',
        sendItemId: [],
        minDate: '',
        maxDate: '',
      },
      cardHight: 0,
      mainHeight: 0,
      defaultStartDate: '',
      defaultEndDate: '',
      showCustomDatepicker: false,
      firstLoading: false,
    }
  },
  created() {
  },
  beforeMount () {
    this.defaultEndDate = new Date()
    let currentDate = new Date()
    this.defaultStartDate = new Date(currentDate.setDate(currentDate.getDate() - 29))
    let filterDataStoredInLocalStorage = localStorage.getItem('extremeDogFenceFilter')
    if (filterDataStoredInLocalStorage !== null) {
      let data = JSON.parse(filterDataStoredInLocalStorage)
      this.filterObject.startDate = data.fromDate
      this.filterObject.endDate = data.toDate
      this.filterObject.city = data.city
      this.filterObject.state = data.state
      this.filterObject.pinCode = data.pinCode
      this.filterObject.payment = data.payment
      this.tempCityList = data.cityArr
      this.tempStateList = data.stateArr
      this.tempPinList = data.zipArr
      this.tempPaymentList = data.payArr
      for (let index = 0; index < this.buttonFiltersArray.length; index++) {
        if (this.buttonFiltersArray[index].value === data.selectedVal) {
          this.buttonFiltersArray[index].selected = true
        } else {
          this.buttonFiltersArray[index].selected = false
        }
      }
    } else {
      this.filterObject.startDate = this.defaultStartDate
      this.filterObject.endDate = this.defaultEndDate
    }
  },
  mounted () {
    document.title = 'Accounting ◾ Ext. Dog Fence'
    // this.$root.$on('setNewTabActive', (index) => {
    //   this.tabsSet(index)
    // })
    this.paymentDebounce = this.debounce(function () {
      this.getPaymentList()
    }, 500)
    this.zipDebounce = this.debounce(function () {
      this.getZipList(false)
    }, 500)
    this.stateDebounce = this.debounce(function () {
      this.getStateList(false)
    }, 500)
    this.cityDebounce = this.debounce(function () {
      this.getCityList(false)
    }, 500)
    this.cardHight = window.innerHeight - 255
    this.mainHeight = window.innerHeight - 145
    console.log('this.cardHight', this.cardHight, this.mainHeight)
    this.apiCaller()
  },
  watch: {
    citySearch: {
      handler() {
        this.cityDebounce()
        // if (this.citySearch.length === 0) {
        //   this.parentCityList = this.reqCityList
        // } else {
        //   let filter = this.citySearch.toLowerCase();
        //   this.parentCityList = []
        //   this.reqCityList.forEach(ELE => {
        //     if (this.citySearch.toLowerCase().indexOf(filter) > -1 === ELE.displayName.toLowerCase().indexOf(filter) > -1) {
        //       this.parentCityList.push(ELE)
        //     }
        //   })
        //   // for (let index = 0; index < this.reqCityList.length; index++) {
        //   //   if (this.citySearch.toLowerCase().indexOf(filter) > -1 === this.reqCityList[index].displayName.toLowerCase().indexOf(filter) > -1) {
        //   //     this.parentCityList.push(this.reqCityList[index])
        //   //   }
        //   // }
        // }
      }
    },
    stateSearch: {
      handler() {
        this.stateDebounce()
        // if (this.stateSearch.length === 0) {
        //   this.parentStateList = this.reqStateList
        // } else {
        //   let filter = this.stateSearch.toLowerCase();
        //   this.parentStateList = []
        //   this.reqCityList.forEach(ELE => {
        //     if (this.stateSearch.toLowerCase().indexOf(filter) > -1 === ELE.displayName.toLowerCase().indexOf(filter) > -1) {
        //       this.parentStateList.push(ELE)
        //     }
        //   })
        //   // for (let index = 0; index < this.reqCityList.length; index++) {
        //   //   if (this.citySearch.toLowerCase().indexOf(filter) > -1 === this.reqCityList[index].displayName.toLowerCase().indexOf(filter) > -1) {
        //   //     this.parentCityList.push(this.reqCityList[index])
        //   //   }
        //   // }
        // }
      }
    },
    pinSearch: {
      handler() {
        this.zipDebounce()
        // if (this.pinSearch.length === 0) {
        //   this.parentPinList = this.reqZipCodeList
        // } else {
        //   let filter = this.pinSearch.toLowerCase();
        //   this.parentPinList = []
        //   this.reqCityList.forEach(ELE => {
        //     if (this.pinSearch.toLowerCase().indexOf(filter) > -1 === ELE.displayName.toLowerCase().indexOf(filter) > -1) {
        //       this.parentPinList.push(ELE)
        //     }
        //   })
        //   // for (let index = 0; index < this.reqCityList.length; index++) {
        //   //   if (this.citySearch.toLowerCase().indexOf(filter) > -1 === this.reqCityList[index].displayName.toLowerCase().indexOf(filter) > -1) {
        //   //     this.parentCityList.push(this.reqCityList[index])
        //   //   }
        //   // }
        // }
      }
    },
    paymentSearch: {
      handler() {
        this.paymentDebounce()
        // if (this.paymentSearch.length === 0) {
        //   this.parentPaymentList = this.reqZipCodeList
        // } else {
        //   let filter = this.paymentSearch.toLowerCase();
        //   this.parentPaymentList = []
        //   this.reqCityList.forEach(ELE => {
        //     if (this.paymentSearch.toLowerCase().indexOf(filter) > -1 === ELE.displayName.toLowerCase().indexOf(filter) > -1) {
        //       this.parentPaymentList.push(ELE)
        //     }
        //   })
        // }
      }
    },
    tempCityList: {
      handler () {
        console.log('tempCityList', this.tempCityList)
        this.filterObject.city = []
        this.tempCityList.forEach(a => {
          if (a.displayName !== '') {
            this.filterObject.city.push(a.displayName)
          }
        })
      },
      deep: true
    },
    tempStateList: {
      handler () {
        console.log('tempStateList', this.tempStateList)
        this.filterObject.state = []
        this.tempStateList.forEach(a => {
          if (a.displayName !== '') {
            this.filterObject.state.push(a.displayName)
          }
        })
      },
      deep: true
    },
    tempPinList: {
      handler () {
        console.log('tempPinList', this.tempPinList)
        this.filterObject.pinCode = []
        this.tempPinList.forEach(a => {
          if (a.displayName !== '') {
            this.filterObject.pinCode.push(a.displayName)
          }
        })
      },
      deep: true
    },
    tempPaymentList: {
      handler () {
        console.log('tempPaymentList', this.tempPaymentList)
        this.filterObject.payment = []
        this.tempPaymentList.forEach(a => {
          if (a.displayName !== '') {
            this.filterObject.payment.push(a.displayName)
          }
        })
      },
      deep: true
    },
    filterObject: {
      handler () {
        console.log('filterObject', this.filterObject)
      },
      deep: true
    },
  },
  methods: {
    lockBtn () {
      console.log('lock btn')
      this.isFilterLock = !this.isFilterLock
    },
    tabsSet (index) {
      this.tabs.forEach((element, i) => {
          if (index === i) {
              element.active = true
              this.activeIndex = i
          } else {
              element.active = false
          }
      });
      this.citySearch = ''
      this.stateSearch = ''
      this.pinSearch = ''
      this.paymentSearch = ''
      this.citySelAll = true
      this.stateSelAll = true
      this.zipSelAll = true
      this.paymentSelAll = true
      this.tempCityList = []
      this.tempStateList = []
      this.tempPinList = []
      this.tempPaymentList = []
      this.filterObject.city = []
      this.filterObject.state = []
      this.filterObject.pinCode = []
      this.filterObject.payment = []
      this.parentCityList = []
      this.parentStateList = []
      this.parentPinList = []
      this.parentPaymentList = []
      this.ExtFListingObj = []
      this.ExtFListingObjRec = null
       this.firstLoading = false
      this.fromApiStartDate = ''
      this.fromApiEndDate = ''
      this.dateDiffCount = ''
      this.isShowAllFilter = false
      this.isFilterOpen = true
      this.apiCaller()
    },
    selectedAll (value) {
      if (value === 'city') {
        this.tempCityList = []
        if (this.citySelAll === true) {
          this.citySelAll = false
        } else {
          this.citySelAll = true
        }
        if (this.citySearch === '') {
          if (this.citySelAll) {
            console.log('stage 1')
            this.tempCityList = []
            for (let index = 0; index < this.parentCityList.length; index++) {
              this.parentCityList[index].isSelected = true
            }
          } else if (!this.citySelAll) {
            this.tempCityList = []
            for (let index = 0; index < this.parentCityList.length; index++) {
              this.parentCityList[index].isSelected = false
              this.tempCityList = []
            }
          }
        } else if (this.citySearch !== '') {
          if (this.citySelAll) {
            console.log('stage 3')
            for (let index = 0; index < this.parentCityList.length; index++) {
              if (this.parentCityList[index].isSelected === false) {
                this.parentCityList[index].isSelected = true
                this.tempCityList.push(this.parentCityList[index])
              }
            }
          } else if (!this.citySelAll) {
            console.log('stage 4')
            this.tempCityList = []
            for (let J = 0; J < this.parentCityList.length; J++) {
              this.parentCityList[J].isSelected = false
            }
          }
        }
      } else if (value === 'state') {
        this.tempStateList = []
        if (this.stateSelAll === true) {
          this.stateSelAll = false
        } else {
          this.stateSelAll = true
        }
        if (this.stateSearch === '') {
          if (this.stateSelAll) {
            console.log('stage 1')
            this.tempStateList = []
            for (let index = 0; index < this.parentStateList.length; index++) {
              this.parentStateList[index].isSelected = true
            }
          } else if (!this.stateSelAll) {
            this.tempStateList = []
            for (let index = 0; index < this.parentStateList.length; index++) {
              this.parentStateList[index].isSelected = false
              this.tempStateList = []
            }
          }
        } else if (this.stateSearch !== '') {
          if (this.stateSelAll) {
            console.log('stage 3')
            for (let index = 0; index < this.parentStateList.length; index++) {
              if (this.parentStateList[index].isSelected === false) {
                this.parentStateList[index].isSelected = true
                this.tempStateList.push(this.parentStateList[index])
              }
            }
          } else if (!this.stateSelAll) {
            console.log('stage 4')
            this.tempStateList = []
            for (let J = 0; J < this.parentStateList.length; J++) {
              this.parentStateList[J].isSelected = false
            }
          }
        }
      } else if (value === 'zip') {
        this.tempPinList = []
        if (this.zipSelAll === true) {
          this.zipSelAll = false
        } else {
          this.zipSelAll = true
        }
        if (this.pinSearch === '') {
          if (this.zipSelAll) {
            console.log('stage 1')
            this.tempPinList = []
            for (let index = 0; index < this.parentPinList.length; index++) {
              this.parentPinList[index].isSelected = true
            }
          } else if (!this.zipSelAll) {
            this.tempPinList = []
            for (let index = 0; index < this.parentPinList.length; index++) {
              this.parentPinList[index].isSelected = false
              this.tempPinList = []
            }
          }
        } else if (this.pinSearch !== '') {
          if (this.zipSelAll) {
            console.log('stage 3')
            for (let index = 0; index < this.parentPinList.length; index++) {
              if (this.parentPinList[index].isSelected === false) {
                this.parentPinList[index].isSelected = true
                this.tempPinList.push(this.parentPinList[index])
              }
            }
          } else if (!this.zipSelAll) {
            console.log('stage 4')
            this.tempPinList = []
            for (let J = 0; J < this.parentPinList.length; J++) {
              this.parentPinList[J].isSelected = false
            }
          }
        }
      } else if (value === 'payment') {
        this.tempPaymentList = []
        if (this.paymentSelAll === true) {
          this.paymentSelAll = false
        } else {
          this.paymentSelAll = true
        }
        if (this.paymentSearch === '') {
          if (this.paymentSelAll) {
            console.log('stage 1')
            this.tempPaymentList = []
            for (let index = 0; index < this.parentPaymentList.length; index++) {
              this.parentPaymentList[index].isSelected = true
            }
          } else if (!this.paymentSelAll) {
            this.tempPaymentList = []
            for (let index = 0; index < this.parentPaymentList.length; index++) {
              this.parentPaymentList[index].isSelected = false
              this.tempPaymentList = []
            }
          }
        } else if (this.paymentSearch !== '') {
          if (this.paymentSelAll) {
            console.log('stage 3')
            for (let index = 0; index < this.parentPaymentList.length; index++) {
              if (this.parentPaymentList[index].isSelected === false) {
                this.parentPaymentList[index].isSelected = true
                this.tempPaymentList.push(this.parentPaymentList[index])
              }
            }
          } else if (!this.paymentSelAll) {
            console.log('stage 4')
            this.tempPaymentList = []
            for (let J = 0; J < this.parentPaymentList.length; J++) {
              this.parentPaymentList[J].isSelected = false
            }
          }
        }
      }
    },
    resetAll () {
      this.filterObject.startDate = this.defaultStartDate
      this.filterObject.endDate = this.defaultEndDate
      this.resetCity()
      this.resetState()
      this.resetZip()
      this.resetpayment()
      for (let i = 0; i < this.buttonFiltersArray.length; i++) {
        if (this.buttonFiltersArray[i].value === 'last30Days') {
          this.selectedValue = 'last30Days'
          this.buttonFiltersArray[i].selected = true
        } else {
          this.buttonFiltersArray[i].selected = false
        }
      }
      localStorage.removeItem('extremeDogFenceFilter')
    },
    resetCity () {
      this.citySearch = ''
      this.citySelAll = false
      this.filterObject.city = []
      this.tempCityList = []
      for (let index = 0; index < this.parentCityList.length; index++) {
        this.parentCityList[index].isSelected = false
      }
    },
    resetState () {
      this.stateSearch = ''
      this.stateSelAll = false
      this.filterObject.state = []
      this.tempStateList = []
      for (let index = 0; index < this.parentStateList.length; index++) {
        this.parentStateList[index].isSelected = false
      }
    },
    resetZip () {
      this.pinSearch = ''
      this.zipSelAll = false
      this.filterObject.pinCode = []
      this.tempPinList = []
      for (let index = 0; index < this.parentPinList.length; index++) {
        this.parentPinList[index].isSelected = false
      }
    },
    resetpayment () {
      this.paymentSearch = ''
      this.paymentSelAll = false
      this.filterObject.payment = []
      this.tempPaymentList = []
      for (let index = 0; index < this.parentPaymentList.length; index++) {
        this.parentPaymentList[index].isSelected = false
      }
    },
    clearCitySearch () {
      this.citySearch = ''
      // this.getCityList(false)
    },
    clearStateSearch () {
      this.stateSearch = ''
      // this.getStateList(false)
    },
    clearPinSearch () {
      this.pinSearch = ''
      // this.getZipList(false)
    },
    clearPaymentSearch () {
      this.paymentSearch = ''
      // this.getPaymentList()
    },
    openCloseFilter () {
      this.isFilterOpen = !this.isFilterOpen
      console.log('openCloseFilter')
    },
    customDateListener (data) {
      console.log('datadatadatadatadatadata', data)
      this.showCustomDatepicker = false
      if (data !== null) {
        if (data.startDate === null) {
          this.filterObject.startDate = this.defaultStartDate
        } else {
          this.filterObject.startDate = data.startDate
        }
        if (data.endDate === null) {
          this.filterObject.endDate = this.defaultEndDate
        } else {
          this.filterObject.endDate = data.endDate
        }
        // this.getListing()
      } else {
        this.isSelectCustom = false
        // this.filterObject.startDate = this.requiredDataListing.minDate
        // this.filterObject.endDate = this.requiredDataListing.maxDate
      }
    },
    selectData (data, index, value) {
      if (value === 'city') {
        if (!this.citySelAll) {
          data.isSelected = !data.isSelected
          if (data.isSelected) {
            this.tempCityList.push(data)
          } else {
            const findex = this.tempCityList.findIndex(x => x.displayName === data.displayName)
            this.parentCityList[index].isSelected = false
            this.tempCityList.splice(findex, 1)
          }
        } else {
          data.isSelected = !data.isSelected
          this.tempCityList = []
          for (let I = 0; I < this.parentCityList.length; I++) {
            if (this.parentCityList[I].isSelected === true) {
              this.tempCityList.push(this.parentCityList[I])
            }
            this.citySelAll = false   
          }
        }
        if (this.parentCityList.length === this.tempCityList.length) {
          this.citySelAll = true
        }
      } else if (value === 'state') {
        if (!this.stateSelAll) {
          data.isSelected = !data.isSelected
          if (data.isSelected) {
            this.tempStateList.push(data)
          } else {
            const findex = this.tempStateList.findIndex(x => x.displayName === data.displayName)
            this.parentStateList[index].isSelected = false
            this.tempStateList.splice(findex, 1)
          }
        } else {
          data.isSelected = !data.isSelected
          this.tempStateList = []
          for (let I = 0; I < this.parentStateList.length; I++) {
            if (this.parentStateList[I].isSelected === true) {
              this.tempStateList.push(this.parentStateList[I])
            }
            this.stateSelAll = false   
          }
        }
        if (this.parentCityList.length === this.tempCityList.length) {
          this.stateSelAll = true
        }
      } else if (value === 'zip') {
        if (!this.zipSelAll) {
          data.isSelected = !data.isSelected
          if (data.isSelected) {
            this.tempPinList.push(data)
          } else {
            const findex = this.tempPinList.findIndex(x => x.displayName === data.displayName)
            this.parentPinList[index].isSelected = false
            this.tempPinList.splice(findex, 1)
          }
        } else {
          data.isSelected = !data.isSelected
          this.tempPinList = []
          for (let I = 0; I < this.parentPinList.length; I++) {
            if (this.parentPinList[I].isSelected === true) {
              this.tempPinList.push(this.parentPinList[I])
            }
            this.zipSelAll = false   
          }
        }
        if (this.parentPinList.length === this.tempPinList.length) {
          this.zipSelAll = true
        }
      } else if (value === 'payment') {
        if (!this.paymentSelAll) {
          data.isSelected = !data.isSelected
          if (data.isSelected) {
            this.tempPaymentList.push(data)
          } else {
            const findex = this.tempPaymentList.findIndex(x => x.displayName === data.displayName)
            this.parentPaymentList[index].isSelected = false
            this.tempPaymentList.splice(findex, 1)
          }
        } else {
          data.isSelected = !data.isSelected
          this.tempPaymentList = []
          for (let I = 0; I < this.parentPaymentList.length; I++) {
            if (this.parentPaymentList[I].isSelected === true) {
              this.tempPaymentList.push(this.parentPaymentList[I])
            }
            this.paymentSelAll = false   
          }
        }
        if (this.parentPaymentList.length === this.tempPaymentList.length) {
          this.paymentSelAll = true
        }
      }
    },
    getDateTimeForFilterHandler (index, showCustomDatepicker) {
      this.selectedValue = this.buttonFiltersArray[index].value
      this.isSelectCustom = false
      console.log('showCustomDatepicker', index, showCustomDatepicker)
      for (let i = 0; i < this.buttonFiltersArray.length; i++) {
        if (i !== index) {
          this.buttonFiltersArray[i].selected = false
        } else {
          this.buttonFiltersArray[i].selected = true
        }
      }
      if (this.buttonFiltersArray[index].value !== 'today' && this.buttonFiltersArray[index].value !== 'custom') {
        getDayAccordingToLimits(this.buttonFiltersArray[index].value).then(val => {
          console.log('val123123', val)
          this.filterObject.startDate = val.startDate
          this.filterObject.endDate = val.endDate
          // this.getListing()
        })
      } else {
        let date = new Date(new Date().setHours(0, 0, 0, 0)).toUTCString()
        if (this.buttonFiltersArray[index].value === 'today') {
          this.filterObject.startDate = date
          this.filterObject.endDate = date
          // this.getListing()
        } else {
          this.showCustomDatepicker = showCustomDatepicker
          this.isSelectCustom = true
        }
      }
    },
    shoeExtFilter() {
      this.isShowAllFilter = !this.isShowAllFilter
      if (this.isShowAllFilter) {
        this.getCityList(true)
      }
    },
    applyFilter() {
      if (!this.isFilterLock) {
        this.isFilterOpen = false
      }
      this.getListing()
    },
    apiCaller () {
      this.isRefresh = false
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading ...'})
      API.getAccountingRequiredData(
        response => {
          this.requiredDataListing = response.Data === null ? [] : response.Data
          console.log('this.requiredDataListing', this.requiredDataListing)
          this.filterObject.minDate = this.requiredDataListing.minDate
          this.filterObject.maxDate = this.requiredDataListing.maxDate
          this.isRefresh = true
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          // this.getCityList(true)
          // this.filterObject.startDate = this.requiredDataListing.minDate
          // this.filterObject.endDate = this.requiredDataListing.maxDate
          // if (this.dataBase.length > 0 && this.filterObject.sendItemId.length > 0) {
          //   for (let I = 0; I < this.parentListDisplay.length; I++) {
          //     for (let J = 0; J < this.dataBase.length; J++) {
          //       if (this.dataBase[J].iC === this.parentListDisplay[I].iC) {
          //         this.parentListDisplay[I].iSe = true
          //       }
          //     }
          //   }
          // }
            // this.getListing()
          // if (this.filterObject.sendItemId.length > 0) {
          // }
        },
        error => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    getCityList (value) {
      this.isRefresh = false
      this.isCityLoading = true
      API.getCityRequiredData(
        this.citySearch,
        response => {
          this.parentCityList = response.Data === null ? [] : response.Data
          this.isRefresh = true
          if (this.tempCityList.length > 0 && this.filterObject.city.length > 0) {
            for (let I = 0; I < this.parentCityList.length; I++) {
              const findex = this.tempCityList.findIndex(x => x.displayName === this.parentCityList[I].displayName)
              if (findex > -1) {
                this.parentCityList[I].isSelected = true
              }
            }
          } else if (this.citySearch === '' && this.tempCityList.length === 0) {
            for (let I = 0; I < this.parentCityList.length; I++) {
              this.parentCityList[I].isSelected = true
            }
          }
          this.isCityLoading = false
          if (value) {
            this.getStateList(true)
          }
        },
        error => {
          this.isCityLoading = false
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    getStateList (value) {
      this.isRefresh = false
      this.isStateLoading = true
      API.getStateRequiredData(
        this.stateSearch,
        response => {
          this.parentStateList = response.Data === null ? [] : response.Data
          this.isRefresh = true
          if (this.tempStateList.length > 0 && this.filterObject.state.length > 0) {
            for (let I = 0; I < this.parentStateList.length; I++) {
              const findex = this.tempStateList.findIndex(x => x.displayName === this.parentStateList[I].displayName)
              if (findex > -1) {
                this.parentStateList[I].isSelected = true
              }
            }
          } else if (this.stateSearch === '' && this.tempStateList.length === 0) {
            for (let I = 0; I < this.parentStateList.length; I++) {
              this.parentStateList[I].isSelected = true
            }
          }
          this.isStateLoading = false
          if (value) {
            this.getZipList(true)
          }
        },
        error => {
          this.isStateLoading = false
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    getZipList (value) {
      this.isRefresh = false
      this.isZipLoading = true
      API.getZipRequiredData(
        this.pinSearch,
        response => {
          this.parentPinList = response.Data === null ? [] : response.Data
          this.isRefresh = true
          if (this.tempPinList.length > 0 && this.filterObject.pinCode.length > 0) {
            for (let I = 0; I < this.parentPinList.length; I++) {
              const findex = this.tempPinList.findIndex(x => x.displayName === this.parentPinList[I].displayName)
              if (findex > -1) {
                this.parentPinList[I].isSelected = true
              }
            }
          } else if (this.pinSearch === '' && this.tempPinList.length === 0) {
            for (let I = 0; I < this.parentPinList.length; I++) {
              this.parentPinList[I].isSelected = true
            }
          }
          this.isZipLoading = false
          if (value) {
            this.getPaymentList()
          }
        },
        error => {
          this.isZipLoading = false
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    getPaymentList () {
      this.isRefresh = false
      this.isPaymentLoading = true
      API.getPaymentRequiredData(
        this.paymentSearch,
        response => {
          this.parentPaymentList = response.Data === null ? [] : response.Data
          this.isRefresh = true
          if (this.tempPaymentList.length > 0 && this.filterObject.payment.length > 0) {
            for (let I = 0; I < this.parentPaymentList.length; I++) {
              const findex = this.tempPaymentList.findIndex(x => x.displayName === this.parentPaymentList[I].displayName)
              if (findex > -1) {
                this.parentPaymentList[I].isSelected = true
              }
            }
          } else if (this.paymentSearch === '' && this.tempPaymentList.length === 0) {
            for (let I = 0; I < this.parentPaymentList.length; I++) {
              this.parentPaymentList[I].isSelected = true
            }
          }
          this.isPaymentLoading = false
        },
        error => {
          this.isPaymentLoading = false
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    getListing () {
      this.firstLoading = false
      console.log('this.filterObject.startDate 123 123', this.filterObject.startDate)
      console.log('this.filterObject.endDate 123 123', this.filterObject.endDate)
      let utcSTART = moment(this.filterObject.startDate).format("YYYY-MM-DD");
      let utcEND = moment(this.filterObject.endDate).format("YYYY-MM-DD");
      this.$store.dispatch('SetLoader', {status: true, message: 'Please wait, it takes some time ...'})
      this.searchLoader = true
      if (this.filterObject.city.length === 0) {
        this.filterObject.city = []
      }
      if (this.filterObject.state.length === 0) {
        this.filterObject.state = []
      }
      if (this.filterObject.pinCode.length === 0) {
        this.filterObject.pinCode = []
      }
      if (this.filterObject.payment.length === 0) {
        this.filterObject.payment = []
      }
      let Obj = {
        fromDate: this.filterObject.startDate,
        toDate: this.filterObject.endDate,
        city: this.filterObject.city,
        state: this.filterObject.state,
        pinCode: this.filterObject.pinCode,
        payment: this.filterObject.payment,
        selectedVal: this.selectedValue,
        cityArr: this.tempCityList,
        stateArr: this.tempStateList,
        zipArr: this.tempPinList,
        payArr: this.tempPaymentList
      }
      localStorage.setItem('extremeDogFenceFilter', JSON.stringify(Obj))
      if (this.activeIndex === 0) {
        API.GetExtremeDogFencelistingAcc(
          utcSTART,
          utcEND,
          this.filterObject.city,
          this.filterObject.state,
          this.filterObject.pinCode,
          this.filterObject.payment,
          false,
          response => {
            this.ExtFListingObj = response.Data === null ? [] : response.Data
            console.log(response.Data)
            if (this.isSelectCustom === false) {
              for (let i = 0; i < this.buttonFiltersArray.length; i++) {
                if (this.selectedValue === this.buttonFiltersArray[i].value) {
                  this.selectedValueinText = this.buttonFiltersArray[i].title
                }
              }
              this.fromApiStartDate = response.fromDate
              this.fromApiEndDate = response.toDate
            } else {
              this.fromApiStartDate = response.fromDate
              this.fromApiEndDate = response.toDate
              this.getDateCountBetween(response.fromDate, response.toDate)
            }
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            this.searchLoader = false
            this.firstLoading = true
          },
          error => {
            this.resetPaginationValues = false
            this.searchLoader = false
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      } else if (this.activeIndex === 1) {
        API.AccGetExtremeDogFenceReconciliation(
          utcSTART,
          utcEND,
          this.filterObject.city,
          this.filterObject.state,
          this.filterObject.pinCode,
          this.filterObject.payment,
          false,
          response => {
            this.ExtFListingObjRec = response.Data === null ? null : response.Data
            console.log(response.Data)
            if (this.isSelectCustom === false) {
              for (let i = 0; i < this.buttonFiltersArray.length; i++) {
                if (this.selectedValue === this.buttonFiltersArray[i].value) {
                  this.selectedValueinText = this.buttonFiltersArray[i].title
                }
              }
              this.fromApiStartDate = response.Data.fromDate
              this.fromApiEndDate = response.Data.toDate
            } else {
              this.fromApiStartDate = response.Data.fromDate
              this.fromApiEndDate = response.Data.toDate
              this.getDateCountBetween(response.Data.fromDate, response.Data.toDate)
            }
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            this.searchLoader = false
            this.firstLoading = true
          },
          error => {
            this.resetPaginationValues = false
            this.searchLoader = false
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      }
    },
    getDateCountBetween (startDate, endDate) {
      var date1 = new Date(startDate);
      var date2 = new Date(endDate);
      var Difference_In_Time = date2.getTime() - date1.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      this.dateDiffCount = Difference_In_Days === 0 ? 1 : Math.round(Difference_In_Days) + 1
    },
    async exportReport () {
      if (this.firstLoading && (this.ExtFListingObj.length > 0 || this.ExtFListingObjRec !== null )) {
        let utcSTART = moment(this.filterObject.startDate).format("YYYY-MM-DD");
        let utcEND = moment(this.filterObject.endDate).format("YYYY-MM-DD");
        this.searchLoader = true
        if (this.filterObject.sendItemId.length === 0) {
          this.filterObject.sendItemId = []
        }
        let day1 = moment(this.filterObject.startDate).format("MM-DD-YY")
        let day2 = moment(this.filterObject.endDate).format("MM-DD-YY")
        let Time = `${this.activeIndex === 0 ? 'Accounting ExtremeDogFencecom-' : 'ExtremeDogFence.com'}` + day1 + '-To-' + day2
        let URL = this.activeIndex === 0 ? 'api/v1/analytics/edf/accountingReport' : 'api/v1/analytics/edf/reconciliationInfo'
        let data = new FormData()
        data.append('from', utcSTART)
        data.append('to', utcEND)
        data.append('cityList', this.filterObject.city.length > 0 ? JSON.stringify(this.filterObject.city) : '')
        data.append('stateList', this.filterObject.state.length > 0 ? JSON.stringify(this.filterObject.state) : '')
        data.append('zipList', this.filterObject.pinCode.length > 0 ? JSON.stringify(this.filterObject.pinCode) : '')
        data.append('paymentMethodList', this.filterObject.payment.length > 0 ? JSON.stringify(this.filterObject.payment) : '')
        data.append('export', true)
        await this.downloadAttachment(URL, `${Time}` + '.xlsx', data)
      }
    },
    async downloadAttachment (subUrl, fileName, formData) {
      let url = this.$store.getters.liveServerUrl + subUrl
      // If Want To cancel request
      const cancelToken = axios.CancelToken
      const source = cancelToken.source()
      let originalFileName = fileName
      this.$store.dispatch('CancelDownloadRequest', source)
      // Show loader while downloading
      this.$store.dispatch('SetDownloadLoader', true)
      // Calling Api and waiting for response
      let response = await downloadFile(formData, url, source)
      // Checking for status if 200 == success
      if (response.status === 200) {
        this.$store.dispatch('CancelDownloadRequest', null)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([response.data]))
        console.log(window.URL.createObjectURL(new Blob([response.data])))
        link.setAttribute('download', originalFileName)
        document.body.appendChild(link)
        link.click()
        // CLosing loader and reseting text in loader
        this.$store.dispatch('SetDownloadLoader', false)
        this.$store.dispatch('SetDownloadMessage', '')
      } else {
        this.$store.dispatch('CancelDownloadRequest', null)
        this.$store.dispatch('SetDownloadMessage', '')
        this.$store.dispatch('SetDownloadLoader', false)
      }
    },
  },
  destroyed() {
  },
  beforeDestroy () {
    this.$root.$off("setNewTabActive");
  }
}
</script>
<style scoped>
.rightText {
  text-align: right !important;
  justify-content: right !important;
}
.val_col {
  min-width: 95px !important;
  width: 95px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.payment_col {
  min-width: 100px !important;
  width: 100px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.date_col {
  min-width: 150px !important;
  width: 150px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.col_col {
  min-width: 80px !important;
  width: 80px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.small_col2 {
  min-width: 110px !important;
  width: 110px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.small_col {
  min-width: 179px !important;
  width: 179px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
  justify-content: right;
}
.overFlowParaA {
  word-break: break-all !important;
  white-space: initial !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
}

.progress-bar {
  height: 2px;
  background-color: #0a7ef9;
  width: 100%;
  overflow: hidden;
}

.button_position {
  position: absolute;
  z-index: 10!important;
  height: 0px!important;
  /* position: fixed; */
}
.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: #74b4f9;
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
.loader2 {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #0636c7;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 0.9s linear infinite;
}
.centerLine {
  display: flex !important;
  align-items: center !important;
}
.xsmall_col {
  min-width: 54px !important;
  width: 54px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.tempppp:hover {
  overflow: auto;
}
</style>
